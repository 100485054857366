<template>
  <tr>
    <td>
      <strong>{{ deposit.id }}</strong>
    </td>
    <td>
      <strong class="font-medium">{{ deposit.sum }}</strong> $
    </td>
    <td class="nowrap-column">
      {{ deposit.client.title }}
    </td>
    <td class="nowrap-column">
      {{ deposit.creator.name }} (ID {{ deposit.creator.id }})
    </td>
    <td class="nowrap-column">
      {{ deposit.created_date }}
    </td>
    <td class="nowrap-column">
      <button class="btn btn-danger"
              style="margin: 3px 2px 2px 3px"
              title="Удалить"
              type="button"
              v-on:click="removeDeposit">
        <i class="fa fa-trash"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";
import VuejsDialog from 'vuejs-dialog';

Vue.use(VuejsDialog);

export default {
  name: 'car-deposit-item',

  props: {
    deposit: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  methods: {
    removeDeposit() {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/car/' + this.deposit.car_id + '/deposits', {
          data: {
            deposit_id: this.deposit.id,
          }
        }).then(() => {
          this.EventBus.$emit('table-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>

<style scoped>

</style>